import '../Common.scss';
import { Fragment } from 'react';
import { Col } from 'reactstrap';
import logo from '../../../../assets/images/ibs_logo_tp.png';

const Impressum = ({ match }) => (
	<Fragment>
		<div className='ibsBackground h-100'>
			<Col lg='8' className='mx-auto mt-5'>
				<div className='shadowBox p-5'>
					<div className='pb-5'>
						<a href='#/login'>
							<img src={logo} width='150' alt='' />
						</a>
					</div>

					<div className='dialogContent'>
						<h4>Impressum</h4>

						<p>
							<strong>Verantwortlich für den Inhalt:</strong>
							<br />
							ibs Installations-, Bewachungs- und Sicherheitstechnik GmbH
							<br />
							Geschäftsleitung: Marita Pieper und Kai Siegle
							<br />
							Ludwig-Erhard-Straße 4<br />
							45891 Gelsenkirchen
							<br />
							Telefon (0209) 31838-0
							<br />
							Telefax (0209) 31838-99
							<br />
							<strong>E-Mail: </strong> info@ibs-sicherheitstechnik.de
							<br />
							<strong>Registergericht: </strong> Amtsgericht Gelsenkirchen
							<br />
							<strong>Registernummer: </strong> HR B 3485
							<br />
							<strong>Verantwortlich im Sinne § 6 MDStV: </strong> Christoph Pieper
							<br />
							<strong>Vertretungsberechtigter: </strong> Christoph Pieper
							<br />
						</p>
						<p>
							<strong>USt-IdNr.: </strong> DE 159892738
						</p>
						<p>
							<strong>Datenschutzbeauftragter:</strong> Dipl. Ing, Wolfgang Scharf
						</p>
						<p>
							<strong>Copyright und Haftung: </strong>
							Texte, Inhalte sowie Gestaltung dieses Internetauftritts sind urheberrechtlich geschützt. Sie dürfen allenfalls in den engen Grenzen
							des Urheberrechts zum persönlichen, privaten und nicht-kommerziellen Gebrauch genutzt, verbreitet bzw. vervielfältigt werden (z.B.
							Download). Die sonstige Verwendung, auch auszugsweise, ist ohne schriftliche Zustimmung von ibs Installations-, Bewachungs- und
							Sicherheitstechnik GmbH urheberrechtswidrig und strafbar. Insbesondere ist jegliche Nutzung zu Werbe- oder ähnlichen Zwecken
							ausdrücklich untersagt. Auch bei vorheriger schriftlicher Einwilligung von ibs Installations-, Bewachungs- und Sicherheitstechnik
							GmbH ist außerdem eine Vervielfältigung oder Verbreitung der Seite oder ihrer Inhalte nur in Verbindung mit Quellenangabe bzw.
							deutlichen Hinweis auf ibs Installations-, Bewachungs- und Sicherheitstechnik GmbH zulässig. Auch wenn Marken, Logos oder
							geschäftliche Bezeichnungen etc. verwendet werden, gelten die entsprechenden Schutzbestimmungen.
						</p>
						<p>
							<strong>DISCLAIMER: </strong>
							Alle Angaben unseres Internet-Angebotes wurden sorgfältig geprüft. Wir bemühen uns, dieses Informationsangebot stetig zu erweitern
							und zu aktualisieren. Eine Garantie für die Vollständigkeit, Richtigkeit und letzte Aktualität kann jedoch nicht übernommen werden.
						</p>
					</div>
				</div>
			</Col>
		</div>
	</Fragment>
);

export default Impressum;
